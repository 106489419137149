<template>
	<div class="cs-table-block cs-block" :class="`${styleClass}${hasBg}`">
		<div class="cs-base-block">
			<div class="container">
				<h2 class="cs-title" v-if="data.title">
					<ColorSplit :data="data.title" />
				</h2>
				<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
				<span class="cs-text" v-if="data.text" v-html="data.text"></span>
				<CSTable class="cs-system-table-block" :id="data.id" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Table",
	components: {
		ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
		CSTable: () => import("../components/CSTable.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...computed('Table'),
		styleClass() {
			let result = ''
    
			if(this.data.style) result += `cs-style-${this.data.style}`

			if (this.data.style == 'light') result += ' has-background'

			return result
		},
		hasBg() {
			if (this.data.style == 'color') return ' has-background';

			return '';
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-table-block {
	.cs-system-table-block {
		margin-top: 30px;
	}
	&.cs-style- {
		&color {
			@include cs-block-color;
			.cs-sub-title {
				color: $sub_title_color;
			}
			*{
				color: white;
			}
            ::v-deep .thead{
                border-color: #c6dfed !important;
            }
		}

		&light {
			@include cs-block-light-image;
			
			.cs-sub-title {
				color: $sub_title_color_light;
			}
		}

		&dark {
			@include cs-block-dark;
			.cs-sub-title {
				color: $sub_title_color_dark;
			}
            ::v-deep {
                .table {
                    background: #fff !important;
                }
            }
		}
	}

	.cs-sub-title {
		font-weight: 400;
		padding: 10px 0;
		font-size: 20px;
	}
}
</style>
